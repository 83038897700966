/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
  outline: none !important;
  line-height: normal;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "./app/shared/_scss/global/custom.scss";
@import "./app/shared/_scss/global/app.scss";
@import "./app/shared/_scss/global/libs-overrides.scss";
