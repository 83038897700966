@mixin icon-size($size) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size !important;
}

@mixin scalable-item($size: 1.1) {
  transition: all 0.1s ease-in;

  @media (min-width: $tablet) {
    &:hover {
      transform: scale($size);
      z-index: 1;
      box-shadow: 0 0 19px 6px rgba(51, 51, 51, 0.18);
    }
  }
}
