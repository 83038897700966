@import "../colors.scss";
@import "../minins/common.mixin.scss";

.mat-flat-button {
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 8px !important;

  &.mat-basic {
    line-height: 44px;
    color: color(blue);
    background-color: color(white);
    border: 1px solid color(gray);
  }

  &.mat-primary {
    line-height: 44px;
  }

  &[disabled] {
    background-color: color(gray, 200);
    color: color(white) !important;
    border: none;
  }
}

.mat-drawer-container {
  background-color: color(white);
}

.mat-drawer-content.mat-sidenav-content {
  display: flex;
  flex-direction: column;
}

.rs-sign-in {
  .mat-form-field-infix {
    height: 51px !important;
  }

  .mat-form-field-label {
    font-size: 18px !important;
    color: color(gray) !important;
  }

  .mat-form-field-infix input {
    color: color(dark, 100) !important;
    font-size: 18px !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #dfe2e4;
    height: 1.8px !important;
  }
  .mat-form-field .mat-form-field-ripple,
  .mat-form-field .mat-form-field-ripple.mat-accent {
    height: 1.8px !important;
  }
}

.simple-notification-wrapper {
  z-index: 1003 !important;
}

.mat-sidenav-container {
  height: calc(100% - #{$header-height-small});
  width: 100%;
  position: fixed !important;

  @media (min-width: $laptop) {
    height: calc(100% - #{$header-height});
  }
}

.mat-drawer-content {
  overflow-x: hidden !important;
}

.mat-sidenav {
  position: fixed;
  margin-right: 2px;
}

.mat-dialog {
  &__header {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    color: color(white);
    background-color: color(blue);
    border-radius: 6px 6px 0 0;

    h2 {
      margin: 0;
      margin-left: 14px;
      font-size: 20px;
    }

    @media (min-width: $laptop) {
      font-size: 28px;
    }

    mat-icon {
      @include icon-size(32px);
    }
  }
}
