@import "../variables.scss";

/* Importing Bootstrap SCSS file. */
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: $desktop-container,
) !default;

$grid-gutter-width: $gutter-width !default;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@import "~bootstrap/scss/bootstrap";
