@import "../colors.scss";
@import "../breakpoints.scss";

.rs-layout {
  color: color(dark, 100);

  &__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
  }

  :focus {
    outline: none;
  }
}

.rs-dialog {
  .mat-dialog-container {
    padding: 0;
    border-radius: 6px;
    overflow: inherit;

    @media (min-width: $tablet) {
      margin: 20px;
    }

    .mat-dialog-content {
      margin: 0;
      padding: 20px 30px;

      @media (min-width: $tablet) {
      }
    }

    .mat-dialog-actions {
      margin: 0;
      padding: 20px 30px;

      @media (min-width: $tablet) {
      }
    }
  }
}

.rs-clickable-icon {
  cursor: pointer;
}

.rs-custom-placeholder {
  color: color(gray, 700);
  font-size: 16px;
  position: absolute;
  top: 28px;
  left: 10px;
}

.rs-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rs-dialog-button.mat-flat-button {
  min-width: 120px ;
  margin-left: 15px ;
}
